<template>
  <v-dialog :value="GalleryDialogValue" max-width="600px" :persistent="true">
    <v-card>
      <v-card-title class="headline">Galery</v-card-title>

      <v-card-text>
        <v-row align="center" class="mx-0">
          <div>{{ /*table.tempItem.id*/ }}</div>
        </v-row>
      </v-card-text>
      <v-tabs color="blue accent-4" right>
        <v-tab>Llegada</v-tab>
        <v-tab>Salida</v-tab>
        <v-tab-item key="1">
          <v-container fluid>
            <v-row>
              <v-col v-for="i in arriveImages" :key="i" cols="12" md="4">
                <v-img
                  :src="`data:image;base64,${i}`"
                  :lazy-src="`data:image;base64,${i}`"
                  aspect-ratio="1"
                >
                  <v-row
                    class="fill-height flex-column"
                    justify="center"
                    align="center"
                  >
                    <div class="align-self-center">
                      <v-btn
                        color="rgba(255, 255, 255, 0)"
                        icon
                        @click="onVideoOpen(i)"
                      >
                        <v-icon color="white"> mdi-eye-outline </v-icon>
                      </v-btn>
                    </div>
                  </v-row>
                </v-img>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <v-tab-item key="2">
          <v-container fluid>
            <v-row>
              <v-col v-for="i in returnImages" :key="i" cols="12" md="4">
                <v-img
                  :src="`data:image;base64,${i}`"
                  :lazy-src="`data:image;base64,${i}`"
                  aspect-ratio="1"
                >
                  <v-row
                    class="fill-height flex-column"
                    justify="center"
                    align="center"
                  >
                    <div class="align-self-center">
                      <v-btn
                        color="rgba(255, 255, 255, 0)"
                        icon
                        @click="onVideoOpen(i)"
                      >
                        <v-icon color="white"> mdi-eye-outline </v-icon>
                      </v-btn>
                    </div>
                  </v-row>
                </v-img>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
      </v-tabs>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closeGalleryDialog"
          >Cerrar</v-btn
        >
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
    <MultimediaDialog
      :value="videoForm"
      :close="onVideoClose"
      :media="data"
      :isPassedImage="true"
    />
  </v-dialog>
</template>

<script>
import MultimediaDialog from "../../Complements&Buttons/videoDialog.vue";
export default {
  data() {
    return {
      videoForm: false,
      data: null,
    };
  },
  methods: {
    onVideoClose() {
      this.videoForm = false;
      
    },
    onVideoOpen(value) {
      this.videoForm = true;
      this.data = value;
    },
  },
  props: {
    GalleryDialogValue: Boolean,
    arriveImages: Array,
    returnImages: Array,
    closeGalleryDialog: Function,
  },
  components: {
    MultimediaDialog,
  },
};
</script>