<template>
    <v-menu
        :value="Dialog"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="auto"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
            :value="DateValue"
            :label="Label"
            persistent-hint
            prepend-icon="mdi-calendar"
            v-bind="attrs"
            v-on="on"
            outlined
            readonly
            ></v-text-field>
        </template>
        <v-date-picker
            :value="DateValue"
            no-title
            @change="Fn"
        ></v-date-picker>
    </v-menu>
</template>
<script>
export default {
    props:{
        DateValue: String,
        Label:  String,
        Dialog: Boolean,
        Fn: Function
    }
}
</script>