<template>
<v-dialog
        :value="serviceReportDialog.status"
        persistent
        max-width="600px"
        
      >
    <v-card >
        <v-card-title>
          <span class="headline">Descargar Reporte</span>
        </v-card-title>
        <RadioGroup
          class="ml-2"
          :Options="[{value:'EXCEL',label:'Excel'},{value:'PDF',label:'PDF'}]" 
          :Value='serviceReportDialog.typeOfFile' 
          :Fn="updateTypeOfFile"
        />

        <v-tabs color="blue accent-4" centered @change="cleanInputs()">
                    <v-tab>Por Dispositivo</v-tab>
                    <v-tab>Por Cliente</v-tab>
                    <v-tab>De Refacciones</v-tab>

                    <v-tab-item  key=1>
                      <v-container>
                        <v-row>
                          <v-col
                              cols="12"
                          >
                            <Autocomplete  
                              :Value="dropdownDevice" 
                              :Fn="updateDropdownDevice" 
                              :Items="dropdownDevices" 
                              :Rules="[]"
                              :GetValues='getDropdownDevices'
                              Label="Dispositivo"
                            />
                            <DatePicker Label="De" :Fn="updateDateInitReport" :DateValue="dateInitReport.date" :Dialog="dateInitReport.dialog"/>
                            <DatePicker Label="Hasta" :Fn="updateDateEndReport" :DateValue="dateEndReport.date" :Dialog="dateEndReport.dialog"/>
                          </v-col>
                          <v-col cols="12" align="center">
                            <v-btn
                              v-if="dropdownDevice"
                              rounded
                              color="primary"
                              dark
                              center
                              @click="getReportByDevice()"
                            >
                              Generar reporte
                            </v-btn>
                          </v-col>
                        </v-row>
                    </v-container>
                    </v-tab-item>
                    <v-tab-item  key=2>
                      <v-container>
                        <v-row>
                          <v-col
                              cols="12"
                          >
                            <Autocomplete 
                              :Value="dropdownClient" 
                              :Fn="updateDropdownClient" 
                              :Items="dropdownClients" 
                              :Rules="[]"
                              :GetValues='getDropdownClients'
                              Label="Cliente"
                              :multiselect='true'
                            />
                            <DatePicker Label="De" :Fn="updateDateInitReport" :DateValue="dateInitReport.date" :Dialog="dateInitReport.dialog"/>
                            <DatePicker Label="Hasta" :Fn="updateDateEndReport" :DateValue="dateEndReport.date" :Dialog="dateEndReport.dialog"/>
                          </v-col>
                          <v-col cols="12" align="center">
                            <v-btn
                              v-if="dropdownClient"
                              rounded
                              color="primary"
                              dark
                              center
                              @click="getReportByClient()"
                            >
                              Generar reporte
                            </v-btn>
                          </v-col>
                        </v-row>
                    </v-container>
                    
                    </v-tab-item>
                    <v-tab-item  key=3>
                      <v-container>
                        <v-row>
                          <v-col
                              cols="12"
                          >
                            <DatePicker Label="De" :Fn="updateDateInitReport" :DateValue="dateInitReport.date" :Dialog="dateInitReport.dialog"/>
                            <DatePicker Label="Hasta" :Fn="updateDateEndReport" :DateValue="dateEndReport.date" :Dialog="dateEndReport.dialog"/>
                          </v-col>
                          <v-col cols="12" align="center">
                            <v-btn
                              v-if="dateInitReport.date&&dateEndReport.date"
                              rounded
                              color="primary"
                              dark
                              center
                              @click="getSparesReport()"
                            >
                              Generar reporte
                            </v-btn>
                          </v-col>
                        </v-row>
                    </v-container>
                    
                    </v-tab-item>
                  </v-tabs>
        <v-card-text>
          
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeServiceReportDialog()"
          >
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
import Autocomplete from '../../StandardizedComponents/autocompleteMultiselect.vue.vue'
import RadioGroup from '../../StandardizedComponents/radioGroup'
import DatePicker from '../../StandardizedComponents/datePicker'

import { mapActions, mapMutations,mapState} from "vuex";
export default {
    name: "cuestionarioReporte",
    components:{
      Autocomplete,
      RadioGroup,
      DatePicker
    },
    computed:{
      ...mapState('Reports',['serviceReportDialog','dateInitReport','dateEndReport']),
      ...mapState('Devices/FetchDevices',['dropdownDevices','dropdownDevice']),
      ...mapState('Clients',['dropdownClient','dropdownClients'])
    },
    methods: {
      ...mapMutations("Reports", [
        'updateTypeOfFile',
        'updateTypeOfReport',
        'updateInformationReport',
        'updateDateInitReport',
        'updateDateEndReport'
      ]),
    ...mapActions("Reports",[
        'getSparesReport',
        'closeServiceReportDialog',
        'getReportByDevice',
        'getReportByClient',
        'cleanInputs'
      ]),
    ...mapActions('Devices/FetchDevices',['getDropdownDevices']),
    ...mapMutations('Devices/FetchDevices',['updateDropdownDevice']),
    ...mapActions('Clients',['getDropdownClients']),
    ...mapMutations('Clients',['updateDropdownClient'])
    }
}
</script>