<template>
  <td :colspan="headerLength">
    ¿Se aplicó la garantía?
    <strong>{{ convertBool2String(item.warranty) }}</strong
    ><br />
    ¿Se realizó un reemplazo?
    <strong>{{ convertBool2String(item.radio_replacement) }}</strong
    ><br />
    ¿Se realizó revisión en el laboratorio?
    <strong>{{ convertBool2String(item.lab_review) }}</strong
    ><br />
    ¿Se brindó soporte en línea?
    <strong>{{ convertBool2String(item.online_support) }}</strong
    ><br />
    ¿Se realizaron cambios en línea?
    <strong>{{ convertBool2String(item.online_changes) }}</strong
    ><br />
    Comentarios: <strong>{{ item.comments }}</strong><br/>
    <v-chip-group v-if="sparesOfService.length!=0" :show-arrows='true'>
      <v-chip
        @click="openUpdateSpareDialog({id: Spare.ID, name: Spare.Spare, SN: Spare.SN})"
        v-for="(Spare,index) in sparesOfService"
        color="primary"
        :key="index"
      >
        {{ Spare.Spare}}/{{Spare.SN}}
      </v-chip>
  </v-chip-group>
  </td>
  
</template>

<script>
import {mapActions, mapState} from 'vuex'
export default {
    props:{
        headerLength: Number,
        item: Object,
        convertBool2String: Function,
    },
    methods:{
      ...mapActions("Spares", ['openUpdateSpareDialog'])
    },
    computed:{
      ...mapState("Spares", ['sparesOfService']),
    },
}
</script>