var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[_c('v-app',{attrs:{"id":"create"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.table.headers,"items":_vm.table.services,"single-expand":_vm.table.singleExpand,"expanded":_vm.expanded,"item-key":"service_id","show-expand":"","loading":_vm.table.loading,"no-data-text":"No data found","loading-text":"Cargando...","dense":"","search":_vm.table.search},on:{"update:expanded":function($event){_vm.expanded=$event},"item-expanded":_vm.getSparesOfService},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('RowDropdown',{attrs:{"headerLength":headers.length,"item":item,"convertBool2String":_vm.convertBool2String}})]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-row',[_c('GalleryDialog',{attrs:{"GalleryDialogValue":_vm.table.dialogGalery,"arriveImages":_vm.table.ShowingArriveImages,"returnImages":_vm.table.ShowingReturnImages,"closeGalleryDialog":_vm.CLOSE_GALERY_DIALOG}}),_c('v-spacer'),_c('SearchBar',{attrs:{"setSearchTerm":_vm.setSearchterm}})],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('TableButtons',{attrs:{"Buttons":[
              {
                  icon:'mdi-image',
                  Fn:_vm.getImagesByServiceID,
                  fnParams:{id:item.service_id}
              } ]}})]}},{key:"no-data",fn:function(){return [_vm._v(" No registers detected ")]},proxy:true}])}),_c('Reports'),_c('EditSpare'),_c('FAB',{attrs:{"buttons":[
        {
          color: 'primary',
          fn: _vm.openServiceReportDialog,
          icon: 'mdi-file-download',
          name: 'Reportes de servicios'
        }
      ]}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }