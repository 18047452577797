<template>
  <div id="app">
      <v-app id="inspire">
        <Navbar/>
        <Table class="mt-3"/>
      </v-app>
  </div>
</template>

<script>
//import Table from '../components/Table2.vue';
import Table from '../components/Tables/dataTable';
import Navbar from '../components/Navbar';
import { mapState } from 'vuex';

export default {
    computed: {
        ...mapState('moduloStepper', ['Message'])
    },
    components:{
        Table,
        Navbar,
    },
}
</script>

<style scoped>

</style>