<template>
  <div id="app">
    <v-app id="create">
      <v-data-table
        :headers="table.headers"
        :items="table.services"
        :single-expand="table.singleExpand"
        :expanded.sync="expanded"
        item-key="service_id"
        show-expand
        class="elevation-1"
        :loading="table.loading"
        no-data-text="No data found"
        loading-text="Cargando..."
        dense
        :search="table.search"
        @item-expanded="getSparesOfService"
      >
        <template v-slot:expanded-item="{ headers, item }">

          <RowDropdown :headerLength="headers.length" :item="item" :convertBool2String="convertBool2String"/>  
        </template>
        <template v-slot:top>
          <v-toolbar flat>
            <v-row>

              <GalleryDialog :GalleryDialogValue="table.dialogGalery"
              :arriveImages="table.ShowingArriveImages"
              :returnImages="table.ShowingReturnImages"
              :closeGalleryDialog="CLOSE_GALERY_DIALOG"/>
              


              <v-spacer></v-spacer>
              <SearchBar :setSearchTerm="setSearchterm"/>              
            </v-row>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <TableButtons
            :Buttons="[
                {
                    icon:'mdi-image',
                    Fn:getImagesByServiceID,
                    fnParams:{id:item.service_id}
                },
            ]"
          />
        </template>
        <template v-slot:no-data>
          No registers detected
        </template>
      </v-data-table>

                
        <Reports/>
        <EditSpare/>
        <FAB :buttons="[
          {
            color: 'primary',
            fn: openServiceReportDialog,
            icon: 'mdi-file-download',
            name: 'Reportes de servicios'
          }
        ]"/>
    </v-app>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import Reports from '../Dialogs/ReportDialogs/CuestionarioReporte'
import GalleryDialog from '../Dialogs/ServicesDialogs/galleryDialog'
import SearchBar from '../Complements&Buttons/searchBar'
import RowDropdown from '../Complements&Buttons/rowDropdown'
import TableButtons from '../Complements&Buttons/tableButtons'
import EditSpare from '../Dialogs/SpareDialogs/updateSpare.vue'
import FAB from '../Complements&Buttons/fab'


export default {
  
  components:{
    Reports,
    GalleryDialog,
    SearchBar,
    RowDropdown,
    TableButtons,
    FAB,
    EditSpare
  },
  computed: {
    ...mapState({
      table: (state) => state.table,
    })
  },
  methods: {
    ...mapActions('Reports',['openServiceReportDialog','openSpareReportDialog']),
    ...mapMutations("table", [
      "SHOW_DELETE_DIALOG",
      "CLOSE_DELETE_DIALOG",
      "SHOW_EDIT_DIALOG",
      "CLOSE_EDIT_DIALOG",
      "SHOW_GALERY_DIALOG",
      "CLOSE_GALERY_DIALOG",
      "SHOW_REPORT_DIALOG",
      "CLOSE_REPORT_DIALOG",
      "setSearchterm",
      "SetEditTextAreaValue",
      "isAdmin",
    ]),
    ...mapActions("table", [
      "getAllServices",
      "allowAdminFunctions",
      "deleteService",
      "setEditedValue",
      "getServiceByID",
      "showDeleteDialog",
      "getImagesByServiceID",
    ]),
    ...mapActions('Spares',['getSparesOfService']),
    convertBool2String(value) {
      if (value === 1) {
        return "Sí";
      } else {
        return "No";
      }
    },
    isAdmin(){
      if(localStorage('isAdmin') === 1)
        return true
      else
        return false
    }
  },
  created() {
    this.$store.dispatch("table/getAllServices");
  },
  data() {
    return {
      expanded: [],
      textAreaValue: "",
      fab: false,
    };
  },
};
</script>